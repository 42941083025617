import React, { useEffect } from 'react'
import Cart from '../../Component/Cart/Cart'

const CartPage = () => {
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Cart />
    </>
  )
}

export default CartPage
