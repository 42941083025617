import React from "react";
import "swiper/css";
import "./styles.css";
import 'swiper/css/navigation';
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
const Slider = () => {
    const Navigate = useNavigate()
    return (
        <>
            <Swiper className="mySwiperslider" navigation={true} modules={[Navigation]}>
                <SwiperSlide>

                    <div className="slider-home-banner md:pl-10" style={{ backgroundImage: 'url("/Images/newbanner.jpg")' }}>
                        <div className="md:w-[55%] w-full backdrop-opacity-20 backdrop-invert p-3 bg-color3/30">
                            {/* <h1 className="md:text-6xl font-body  text-[#fff3ff]"><span className="text-7xl text-[#fff3ff] font-header ">Martha</span > MOVES UP</h1> */}
                            {/* <p className="text-white md:text-lg font-body">POWERFUL, ELEGANT, SIMPLE</p> */}
                            <img src="/Images/mARTHA-01.png" alt="" className="" />
                            <div className="flex justify-center flex-wrap gap-2 mt-5">
                                <button onClick={() => Navigate('/blog')} className="bg-[transparent] font-body text-white text-[25px] border-2 py-1 px-8 md:px-16">Discover</button>
                                <button onClick={() => Navigate('/products')} className="bg-color2 font-body text-white border-2 text-[25px] py-1 px-8 md:px-16">Purchase</button>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>

                    <div className="slider-home-banner md:pl-10" style={{ backgroundImage: 'url("/Images/baner2.jpeg")' }}>
                        <div className="md:w-[55%] w-full backdrop-opacity-20 backdrop-invert p-3 bg-color3/30">
                            {/* <h1 className="md:text-6xl font-body  text-[#fff3ff]"><span className="text-7xl text-[#fff3ff] font-header ">Martha</span > MOVES UP</h1> */}
                            {/* <p className="text-white md:text-lg font-body">POWERFUL, ELEGANT, SIMPLE</p> */}
                            <img src="/Images/mARTHA-01.png" alt="" className="" />
                            <div className="flex justify-center flex-wrap gap-2 mt-5">
                                <button onClick={() => Navigate('/blog')} className="bg-[transparent] font-body text-white border-2 py-1 px-8 md:px-16">Discover</button>
                                <button onClick={() => Navigate('/products')} className="bg-color2 font-body text-white border-2 py-1 px-8 md:px-16">Purchase</button>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>

<div className="slider-home-banner md:pl-10" style={{ backgroundImage: 'url("/Images/709A9809.jpg")' }}>
    <div className="md:w-[55%] w-full backdrop-opacity-20 backdrop-invert p-3 bg-color3/30">
        {/* <h1 className="md:text-6xl font-body  text-[#fff3ff]"><span className="text-7xl text-[#fff3ff] font-header ">Martha</span > MOVES UP</h1> */}
        {/* <p className="text-white md:text-lg font-body">POWERFUL, ELEGANT, SIMPLE</p> */}
        <img src="/Images/mARTHA-01.png" alt="" className="" />
        <div className="flex justify-center flex-wrap gap-2 mt-5">
            <button onClick={() => Navigate('/blog')} className="bg-[transparent] font-body text-white border-2 py-1 px-8 md:px-16">Discover</button>
            <button onClick={() => Navigate('/products')} className="bg-color2 font-body text-white border-2 py-1 px-8 md:px-16">Purchase</button>
        </div>
    </div>
</div>
</SwiperSlide>
                
            </Swiper>

        </>
    )
}

export default Slider
