import React from 'react'

const Gallery = () => {
    return (
        <>
            <div class="py-2 grid grid-cols-1 md:grid-cols-5 gap-0">
                <div>
                    <img class="h-[40vh] w-full rounded-none" src="/Images/g1.jpg" alt="" />
                </div>
                <div>
                    <img class="h-[40vh] w-full rounded-none" src="/Images/g2.jpg" alt="" />
                </div>
                <div>
                    <img class="h-[40vh] w-full rounded-none" src="/Images/g4.jpg" alt="" />
                </div>
                <div>
                    <img class="h-[40vh] w-full rounded-none" src="/Images/g3.jpg" alt="" />
                </div>

                <div>
                    <img class="h-[40vh] w-full rounded-none" src="/Images/g5.jpg" alt="" />
                </div>
            </div>

        </>
    )
}

export default Gallery