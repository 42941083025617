/* eslint-disable jsx-a11y/anchor-is-valid */
import { Card } from 'flowbite-react'
import React from 'react'

const CardSec = ({ image, heading, para }) => {
    return (
        <>
            <Card className="min-h-[40vh] w-4/12  border-0 shadow-2xl">
                <div className="flex flex-col items-center rounded-2xl ">
                    <h5 className=" font-body mb-3 text-[20px] md:text-[30px] text-center font-medium text-black dark:text-white uppercase">{heading}</h5>
                    <img
                        alt="Bonnie imge"
                        height="96"
                        src={image}
                        width="96"
                        className=" rounded-full shadow-none"
                    />
                    <ul className='mt-5'>
                        {
                            para?.map((pa) => {
                                return <li className="text-[25px] text-start font-body text-color4  dark:text-gray-400">{pa}</li>

                            })
                        }
                    </ul>
                </div>
            </Card>
        </>
    )
}

export default CardSec