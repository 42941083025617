/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const SignUp = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []); 

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loader state


  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required'),
    address: Yup.string().required('Address is required'),
    phone: Yup.string().required('Phone number is required'),
    terms: Yup.bool().oneOf([true], 'You must accept the terms and conditions'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      address: '',
      phone: '',
      terms: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true); // Show loader
      try {
        const res = await axios.post("https://martha-move-up.developer-iu.xyz/api/client/register", values);
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res?.data?.errors?.email?.[0] || res?.data?.errors?.password?.[0] || "An error occurred during registration.",
          });
        } else {
          localStorage.setItem("token", res.data.accessToken);
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Registration successful.",
          });
          setTimeout(() => {
            navigate('/');
          }, 500);
        }
      } catch (error) {
        const errorMsg = error.response?.data?.errors?.email?.[0] || error.response?.data?.errors?.password?.[0] || "An unexpected error occurred.";
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMsg,
        });
      } finally {
        setLoading(false); // Hide loader
      }
    },
  });
  

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:min-h-screen">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-[18px] md:text-[30px] font-bold leading-tight tracking-tight text-color2 font-header md:text-2xl dark:text-white">
              Create an account
            </h1>
            <form onSubmit={formik.handleSubmit} className="space-y-4 md:space-y-6">
              {/* First Name Field */}
              <div>
                <label htmlFor="firstName" className="block mb-2 text-[18px] md:text-[25px] font-medium text-color2 font-header dark:text-white">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  className="bg-gray-50 border border-color2 text-color2 text-[18px] md:text-[25px] rounded-lg focus:ring-color2 focus:border-color2 block w-full p-2.5 dark:bg-color2 dark:border-color2 dark:placeholder-color2 dark:text-white dark:focus:ring-color2 dark:focus:border-color2"
                  required=""
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <p className="text-red-500 text-xs">{formik.errors.firstName}</p>
                ) : null}
              </div>

              {/* Last Name Field */}
              <div>
                <label htmlFor="lastName" className="block mb-2 text-[18px] md:text-[25px] font-medium  text-color2 font-header dark:text-white">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                 className="bg-gray-50 border border-color2 text-color2 text-[18px] md:text-[25px] rounded-lg focus:ring-color2 focus:border-color2 block w-full p-2.5 dark:bg-color2 dark:border-color2 dark:placeholder-color2 dark:text-white dark:focus:ring-color2 dark:focus:border-color2"
                  required=""
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <p className="text-red-500 text-xs">{formik.errors.lastName}</p>
                ) : null}
              </div>

              {/* Email Field */}
              <div>
                <label htmlFor="email" className="block mb-2 text-[18px] md:text-[25px] font-medium  text-color2 font-header dark:text-white">
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                   className="bg-gray-50 border border-color2 text-color2 text-[18px] md:text-[25px] rounded-lg focus:ring-color2 focus:border-color2 block w-full p-2.5 dark:bg-color2 dark:border-color2 dark:placeholder-color2 dark:text-white dark:focus:ring-color2 dark:focus:border-color2"
                  placeholder="name@company.com"
                  required=""
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-red-500 text-xs">{formik.errors.email}</p>
                ) : null}
              </div>

              {/* Password Field */}
              <div>
                <label htmlFor="password" className="block mb-2 text-[18px] md:text-[25px] font-medium  text-color2 font-header dark:text-white">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  placeholder="••••••••"
                   className="bg-gray-50 border border-color2 text-color2 text-[18px] md:text-[25px] rounded-lg focus:ring-color2 focus:border-color2 block w-full p-2.5 dark:bg-color2 dark:border-color2 dark:placeholder-color2 dark:text-white dark:focus:ring-color2 dark:focus:border-color2"
                  required=""
                />
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-red-500 text-xs">{formik.errors.password}</p>
                ) : null}
              </div>

              {/* Confirm Password Field */}
              <div>
                <label htmlFor="confirm-password" className="block mb-2 text-[18px] md:text-[25px] font-medium  text-color2 font-header dark:text-white">
                  Confirm password
                </label>
                <input
                  type="password"
                  name="passwordConfirmation"
                  id="confirm-password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  placeholder="••••••••"
                   className="bg-gray-50 border border-color2 text-color2 text-[18px] md:text-[25px] rounded-lg focus:ring-color2 focus:border-color2 block w-full p-2.5 dark:bg-color2 dark:border-color2 dark:placeholder-color2 dark:text-white dark:focus:ring-color2 dark:focus:border-color2"
                  required=""
                />
                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? (
                  <p className="text-red-500 text-xs">{formik.errors.passwordConfirmation}</p>
                ) : null}
              </div>

              {/* Address Field */}
              <div>
                <label htmlFor="address" className="block mb-2 text-[18px] md:text-[25px] font-medium  text-color2 font-header dark:text-white">
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  className="bg-gray-50 border border-color2 text-color2 text-[18px] md:text-[25px] rounded-lg focus:ring-color2 focus:border-color2 block w-full p-2.5 dark:bg-color2 dark:border-color2 dark:placeholder-color2 dark:text-white dark:focus:ring-color2 dark:focus:border-color2"
                  required=""
                />
                {formik.touched.address && formik.errors.address ? (
                  <p className="text-red-500 text-xs">{formik.errors.address}</p>
                ) : null}
              </div>

              {/* Phone Field */}
              <div>
                <label htmlFor="phone" className="block mb-2 text-[18px] md:text-[25px] font-medium  text-color2 font-header dark:text-white">
                  Phone Number
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                    className="bg-gray-50 border border-color2 text-color2 text-[18px] md:text-[25px] rounded-lg focus:ring-color2 focus:border-color2 block w-full p-2.5 dark:bg-color2 dark:border-color2 dark:placeholder-color2 dark:text-white dark:focus:ring-color2 dark:focus:border-color2"
                  required=""
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <p className="text-red-500 text-xs">{formik.errors.phone}</p>
                ) : null}
              </div>

              {/* Terms and Conditions Field */}
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="terms"
                    type="checkbox"
                    name="terms"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.terms}
                      className="bg-gray-50 border border-color2 text-color2 text-[18px] md:text-[25px] rounded-lg focus:ring-color2 focus:border-color2 block w-full p-2.5 dark:bg-color2 dark:border-color2 dark:placeholder-color2 dark:text-white dark:focus:ring-color2 dark:focus:border-color2"
                    required=""
                  />
                </div>
                <label htmlFor="terms" className="ml-2 text-[18px] md:text-[25px] font-medium text-color2 font-body dark:text-gray-300">
                  I accept the terms and conditions
                </label>
                {formik.touched.terms && formik.errors.terms ? (
                  <p className="text-red-500 text-xs">{formik.errors.terms}</p>
                ) : null}
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={loading}
                className="w-full text-white bg-color2 hover:bg-color2 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-[18px] md:text-[25px] px-5 py-2.5 text-center dark:bg-color2 dark:hover:bg-color2 dark:focus:ring-gray-700 dark:border-gray-700"
              >
                {loading ? 'Loading...' : 'Sign Up'}
              </button>
            </form>
            <p className="text-[18px] md:text-[25px] font-light text-color4 dark:text-color4 font-body">
              Already have an account?{' '}
              <Link to="/login" >
              <a href="/login" className="font-medium text-color4 font-body hover:underline dark:text-white">
                login
              </a>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
