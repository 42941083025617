/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
const Login = () => {
     const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []); 

    const initialValues = {
        email: '',
        password: '',
        remember: false,
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const res = await axios.post("https://martha-move-up.developer-iu.xyz/api/client/login", values);
            if(res.data.status){
                localStorage.setItem("token", res.data.accessToken);
                setTimeout(() => {
                    navigate('/');
                }, 500);
            }else{
                console.log(res?.data?.errors?.password[0],'gg')
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: res?.data.errors.email[0] || res?.data?.errors?.password[0],
                  });
            }
        } catch (error) {
            console.error('Error during login:', error);
        } finally {
            setLoading(false);
        }
    };


    return (
                <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-[18px] md:text-[25px] font-bold leading-tight tracking-tight text-color2 font-header md:text-2xl dark:text-white">
                            Login
                        </h1>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {() => (
                                <Form className="space-y-4  md:space-y-6">
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block mb-2  text-[18px] md:text-[25px] font-medium text-color2 font-header dark:text-white"
                                        >
                                            Your email
                                        </label>
                                        <Field
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="bg-gray-50 border border-gray-300 text-color2 rounded-lg focus:ring-color2 focus:border-color2 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-color2 dark:focus:border-color2"
                                            placeholder="example@gmail.com"
                                        />
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="text-red-600 text-[18px] md:text-[25px]"
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="password"
                                            className="block mb-2 text-[18px] md:text-[25px] font-medium text-color2 font-header dark:text-white"
                                        >
                                            Password
                                        </label>
                                        <Field
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder="••••••••"
                                            className="bg-gray-50 border border-gray-300 text-color2 rounded-lg focus:ring-color2 focus:border-color2 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-color2 dark:focus:border-color2"
                                        />
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="text-red-600 text-[18px] md:text-[25px]"
                                        />
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <Field
                                                    id="remember"
                                                    name="remember"
                                                    type="checkbox"
                                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                                />
                                            </div>
                                            <div className="ml-3 text-[18px] md:text-[25px]">
                                                <label
                                                    htmlFor="remember"
                                                    className=" font-body text-color4 dark:text-gray-300"
                                                >
                                                    Remember me
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-full text-white bg-color2 hover:bg-color2 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-[18px] md:text-[25px] px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-color2 dark:focus:ring-color2"
                                        disabled={loading}
                                    >
                                        {loading ? 'Loading...' : 'Sign in'}
                                    </button>
                                    <p className="text-[18px] md:text-[25px]  font-body text-color4">
                                        Don’t have an account yet?{' '}
                                        <Link to='/signup' >
                                        <a
                                            href="/signup"
                                            className="font-medium font-body text-color4 text-primary-600 hover:underline dark:text-primary-500"
                                            >
                                            Sign up
                                        </a>
                                            </Link>
                                    </p>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
