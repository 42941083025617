import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from '../../Component/Header';
import './styleblog.css'

const BlogDetail = () => {
  
  const { id } = useParams();
  const [blog , setBlog] = useState(null);
  const [loading , setLoading] = useState(true);
  const [error , setError] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await axios.get(`https://martha-move-up.developer-iu.xyz/api/client/post/get/${id}`);
        setBlog(response.data.post);
      } catch (err) {
        setError('Failed to fetch blog details');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [id]);

  if (loading) {
    return <>
      <Header name="Blog Details" image="/Images/709A9946.jpg" />
      <div className='w-full flex justify-center items-center h-[50vh]'>
        <span className="loaderCSSSS"></span>
      </div>
    </>
  }

  if (error) {
    return <>
      <Header name="Blog Details" image="/Images/709A9946.jpg" />
      <div className="text-center text-red-600">{error}</div>;
    </>
  }

  if (!blog) {
    return <>
      <Header name="Blog Details" image="/Images/709A9946.jpg" />
      <div className="text-center">No blog found</div>;
    </>
  }

  return (
    <>
      <Header name="Blog Details" image="/Images/709A9946.jpg"  />
      {/* <div className="max-w-4xl mx-auto p-6 bg-white  rounded-lg mt-8">
        <div className="flex flex-col md:flex-row p-5 rounded-2xl shadow-lg">
          <div className="md:w-1/3 mb-4 md:mb-0">
            <img
              src={blog?.picture}
              alt={blog?.title}
              className="rounded-lg h-full object-cover"
            />
          </div>
          <div className="md:w-2/3 md:pl-6">
            <h1 className="text-3xl font-bold mb-4 text-color2 font-header">{blog?.title}</h1>
                  <p className="text-sm text-color4 text-left font-body">
                Published on: {new Date(blog.created_at).toLocaleDateString()}
              </p>
     
          </div>
        </div>
      </div> */}
      <div className="flex flex-col md:w-8/12 w-11/12 container m-auto items-center mt-8">
        <div className='mb-6 text-[18px] md:text-[23px]'
          dangerouslySetInnerHTML={{ __html: blog?.description }}
        />
      </div>
    </>
  );
}
export default BlogDetail;