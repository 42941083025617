import React from 'react'

const Contact = () => {
    return (
        <>
            <section class="bg-white dark:bg-white">
                <div class="grid max-w-screen-xl  m-auto lg:gap-8 xl:gap-0   lg:grid-cols-12">
                    <div class=" px-4 pt-8 md:pb-0 pb-5 lg:col-span-7 lg:pt-16 flex justify-center items-center flex-col">
                        <h1 class="mb-4 text-[25px]  tracking-tight leading-none md:text-[60px] text-color2 dark:text-white font-header">Contact Us</h1>
                        {/* <p class="max-w-1xl mb-6 font-light text-center text-color4 lg:mb-8 md:text-lg lg:text-xl dark:text-white font-body">ALorem ipsum dolor sit amet, consectetuer adipiscing elit.
                            Aenean commodo ligula eget dolor. Aenean massa.</p> */}
                        <form className=''>
                            <div className="space-y-5">
                                <div className="border-b border-color4 pb-7">
                                    <div className="mt-4 grid  m-auto grid-cols-1  gap-y-8 sm:grid-cols-6">
                                        <div className="md:col-span-full">
                                            <label htmlFor="username" className="block text-[20px] font-body font-medium leading-6 text-color4">
                                                Full Name
                                            </label>
                                            <div className="mt-2">
                                                <div className="flex border-2 border-color2 md:max-w-lg md:w-96">
                                                    <input
                                                        id="username"
                                                        name="username"
                                                        type="text"
                                                        placeholder="Full Name"
                                                        className="block flex-1 text-[18px] border-0 bg-transparent py-1.5 pl-1 text-color4 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-full">
                                            <label htmlFor="email" className="block text-[20px] font-body font-medium leading-6 text-color4">
                                                Email
                                            </label>
                                            <div className="mt-2">
                                                <div className="flex border-2 border-color2">
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        placeholder="Email"
                                                      className="block flex-1 text-[18px] border-0 bg-transparent py-1.5 pl-1 text-color4 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-full">
                                            <div className="mt-2">
                                                <textarea
                                                    id="about"
                                                    name="about"
                                                    rows={3}
                                                    className="block w-full border-2 border-color2 py-1.5 text-color4  placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                                    defaultValue={''}
                                                />
                                            </div>  </div>


                                    </div>
                                </div>
                            </div>

                            <div className="my-6 flex items-center justify-end gap-x-6">
                                <button
                                    type="submit"
                                    className="w-full bg-color2 font-body px-3 py-2 text-white shadow-sm hover:bg-color5 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-[20px]"
                                >
                                    Save
                                </button>
                            </div>
                        </form>

                    </div>
                    <div class="hidden mt-10  lg:col-span-5 lg:flex h-screen">
                        <img src="/Images/contact.jpg" alt="mockup" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact