/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Slider from '../../Component/Home/Slider'
import CardSec from '../../Component/Home/Card'
import Gallery from '../../Component/Home/Gallery'
import SliderTestimonials from '../../Component/Home/SliderTestimonials'
import Hero from '../../Component/Home/Hero'
import Contact from '../../Component/Home/Contact'
import { useLocation,useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const Home = () => {
    
    const location = useLocation();
    const navigate = useNavigate()
    
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []); 

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');
        
        if (status === 'true') {
            Swal.fire({
                title: "Order",
                text: "Successfull",
                icon: "success"
              });
              setTimeout(()=>{
                navigate('/')
              },1000)
        }
      }, [location.search]);
      
    return (
        <>
        <div className='bg-color3 text-white p-3 text-[20px] md:text-[25px] text-center'>

 FREE SHIPPING FOR US ORDERS $99+
    
        </div>
            <Slider />
            <div className='md:p-16 gap-5 flex flex-wrap md:flex-nowrap md:justify-around justify-center items-center'>
              
                <CardSec image={"/Images/2.png"} heading="SIMPLE" para={['Focus words to be used in your everyday life.','Neutral colored paper to fit into any home decor.']}/>
                <CardSec image={"/Images/3.png"} heading="POWERFUL" para={['Words to help motivate you and elevate your life.']} />
                <CardSec image={"/Images/1.png"} heading="ELEGANT"  para={['Each card is printed on beautiful slightly textured matte paper.', 'Each card set comes with an elegant walnut wood card stand.']}/>
            </div>
            <Gallery />
            <div className='md:p-16'>
                <SliderTestimonials />
            </div>
            <Hero />
            <Contact />
        </>
    )
}

export default Home
