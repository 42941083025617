import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./testimonial.css";
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import { Blockquote } from "flowbite-react";

const SliderTestimonials = () => {
    return (
        <>
            <h1 className="text-center md:text-[35px] text-[25px] font-bold text-color2 uppercase font-body md:mt-8">Elevate Your Life With Words</h1>
            <div className="md:border-b-4 md:w-96 mx-auto mt-5 border-color2"></div>
            <Swiper className="mySwiper" navigation={true} modules={[Navigation]}>
                <SwiperSlide>
                    <figure className="md:mx-auto max-w-screen-md text-center flex justify-center items-center flex-col md:min-h-[50vh]">
                        <Blockquote>
                            <p className="md:text-[25px] text-[20px] font-body  text-color4 dark:text-white">
                                I love these focus word cards. Inspirational sayings and quotes are either hidden (never to be seen again) in phones and books or posted in vinyl on boards as decor, never to be changed. I love that there is a common theme for the month, but I can change the cards every few days, keeping the theme fresh and on my mind.
                                The box of focus cards takes up little space. I appreciate how easy it is to switch out. The displayed card is simple, pretty, and elegant. I've received compliments on it as it is displayed in my entryway. These focus cards keep me encouraged and inspired.
                                I've found them to be very valuable for me and my home.
                            </p>
                        </Blockquote>
                        {/* <Avatar size="lg" img="/Images/testimonials.png" alt="profile picture" className="mt-10" /> */}
                        <figcaption className="mt-6 flex items-center justify-center space-x-3">
                            <div className="flex items-center ">
                                <cite className="pr-3 font-semibold text-color4 dark:text-white font-body">Shannon Thorne, Utah</cite>
                                {/* <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400 font-body">Make Up Artist</cite> */}
                            </div>
                        </figcaption>
                    </figure>
                </SwiperSlide>
                <SwiperSlide>
                    <figure className="mx-auto max-w-screen-md text-center flex justify-center items-center flex-col md:min-h-[50vh]">
                        <Blockquote>
                            <p className="md:text-[25px] text-[20px] font-body  text-color4 dark:text-white">
                                These Positive Mindfulness Display Cards are absolutely beautiful! The cards are printed on high-quality, heavier weight cardstock, and the font is clear and easy to read. Each set comes with a lovely wooden stand, making it easy to display the cards anywhere. The words are thoughtfully chosen, and the accompanying descriptions and reflection questions encourage deeper thinking and mindfulness. I loved the cards so much that I immediately thought of several people I couldn’t wait to share them with. They are perfect for anyone looking to improve their mindset and add positivity to their daily routine. Highly reccommend!
                            </p>
                        </Blockquote>
                        {/* <Avatar size="lg" img="/Images/testimonials.png" alt="profile picture" className="mt-10" /> */}
                        <figcaption className="mt-6 flex items-center justify-center space-x-3">
                            <div className="flex items-center ">
                                <cite className="pr-3 font-semibold text-color4 dark:text-white font-body">Darla Gallew</cite>
                                <cite className="pr-3 font-semibold text-color4 dark:text-white font-body">Satisfied customer, Idaho</cite>
                            </div>
                        </figcaption>
                    </figure>
                </SwiperSlide>

            </Swiper>
        </>
    )
}
export default SliderTestimonials