/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { addToCart } from '../../Redux/cartSlice'
import Swal from 'sweetalert2'
import '../DaynamicProducts/style.css'
const Details = () => {
    const [image, setImage] = useState("")
    const params = useParams()
    const dispatch = useDispatch();
    const [allDataStore, setAllDataStore] = useState()
    const [QuantityForValue, setQuantityForValue] = useState(1)
    const navigate = useNavigate()



    const handleAddToCart = () => {
        dispatch(addToCart({
            image: image,
            id: allDataStore.id,
            name: allDataStore.name,
            price: allDataStore.price,
            quantity: QuantityForValue,
        }));
        Swal.fire({
            title: "Add to Cart",
            text: "Successfull",
            icon: "success"
        });
        setTimeout(() => {
            navigate('/cart')
        }, 500);
    };


    const GetAllData = async () => {
        try {
            const res = await axios.get(`https://martha-move-up.developer-iu.xyz/api/client/product/get/${params.id}`)
            console.log(res?.data?.product, 'res');
            setAllDataStore(res?.data?.product)
            setImage(res?.data?.product?.picture)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {

        GetAllData()
    }, [])



    if (!allDataStore) {
        return (
            <div className='w-full flex justify-center items-center h-[50vh]'>

                <span className="loaderCSSSS"></span>
            </div>
        )
    }



    return (
        <>
            <div class="bg-gray-100">
                <div class="container mx-auto px-4 py-8">
                    <div class="flex flex-wrap -mx-4">
                        {/* <!-- Product Images --> */}
                        <div class="w-full md:w-1/2 px-4 mb-8">
                            <img src={image} alt="Product"
                                class="w-full h-auto rounded-lg shadow-md mb-4" id="mainImage" />
                            <div class="flex gap-4 py-4 justify-center overflow-x-auto">

                                {
                                    allDataStore?.images?.map((e) => {
                                        return (

                                            <img src={e?.path} alt="Thumbnail 1"
                                                class="size-16 sm:size-20 object-cover rounded-md cursor-pointer opacity-60 hover:opacity-100 transition duration-300"
                                                onClick={() => setImage(e?.path)} />
                                        )

                                    })
                                }

                            </div>
                        </div>

                        {/* <!-- Product Details --> */}
                        <div class="w-full md:w-1/2 px-4">
                            <h2 class=" font-semibold mb-2 text-color2 font-header text-[18px] md:text-[30px]">{allDataStore?.name}</h2>
                            <p class="text-color4 mb-4 font-body text-[18px] md:text-[23px]">SKU: {allDataStore?.id}</p>
                            <div class="mb-4">
                                <span class="text-[18px] md:text-[25px] text-color5 font-bold mr-2 font-body">${allDataStore?.price}</span>
                              
                            </div>
                            <div class="flex items-center mb-4 text-[18px] md:text-[23px]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="size-6 text-yellow-500">
                                    <path fill-rule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                        clip-rule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="size-6 text-yellow-500">
                                    <path fill-rule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                        clip-rule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="size-6 text-yellow-500">
                                    <path fill-rule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                        clip-rule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="size-6 text-yellow-500">
                                    <path fill-rule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                        clip-rule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="size-6 text-yellow-500">
                                    <path fill-rule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                        clip-rule="evenodd" />
                                </svg>
                                <span class="ml-2 text-gray-600">5 (4 reviews)</span>
                            </div>
                            <p class="text-color4 mb-2 font-body text-[16px] md:text-[23px]">{allDataStore?.description}</p>
                            <p class="text-color4 mb-3 font-body text-[18px] md:text-[25px]">Stock Available :{allDataStore?.quantity > 0 ? allDataStore?.quantity : "Sold Out"}</p>

                            {/* <div class="mb-6">
                                <h3 class="text-lg font-semibold mb-2">Color:</h3>
                                <div class="flex space-x-2">
                                    <button
                                        class="w-8 h-8 bg-black rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"></button>
                                    <button
                                        class="w-8 h-8 bg-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"></button>
                                    <button
                                        class="w-8 h-8 bg-blue-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"></button>
                                </div>
                            </div> */}

                            <div class="mb-6">
                                <label for="quantity" class="block text-sm font-medium text-color2 mb-1 font-body text-[18px] md:text-[25px]">Quantity:</label>
                                <div className=''>
                                    <button className='p-2 font-body' onClick={() => setQuantityForValue(QuantityForValue - 1)}>
                                        -
                                    </button>
                                    <input type="number" disabled id="quantity" name="quantity" min="1" value={QuantityForValue}
                                        onChange={(e) => setQuantityForValue(e.target.value)}
                                        class="w-12 text-center rounded-md border-gray-300  shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                                    <button className='p-2' onClick={() => setQuantityForValue(QuantityForValue + 1)}>
                                        +
                                    </button>
                                </div>

                            </div>

                            <div class="flex space-x-4 mb-6 text-[18px] md:text-[25px]">
                                <button
                                    onClick={handleAddToCart}
                                    class="bg-color2 flex gap-2 items-center text-white px-6 py-2 rounded-md hover:bg-colro2 focus:outline-none focus:ring-2 focus:ring-color2 focus:ring-offset-2 font-body">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                                    </svg>
                                    Add to Cart
                                </button>
                                {/* <button
                                    class="bg-gray-200 flex gap-2 items-center  text-gray-800 px-6 py-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                                    </svg>
                                    Wishlist
                                </button> */}
                            </div>

                            <div>
                                <h3 class="text-lg font-semibold mb-2 text-color2 font-header text-[18px] md:text-[25px]">Key Features:</h3>
                                <ul class="list-disc list-inside text-color4 font-body text-[18px] md:text-[25px]">
                                    {
                                        allDataStore?.features?.map((e) => {
                                            return (
                                                <li>{e}</li>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default Details
