import React from 'react'

const Header = ({ name, image }) => {
  return (
    <>
      <div
        className="h-[100vh] bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="w-full h-full flex items-center justify-center p-5">
          <h1 className="md:text-7xl text-2xl italic text-white text-center font-body">
            {name}
          </h1>
        </div>
      </div>

    </>
  )
}

export default Header