/* eslint-disable jsx-a11y/img-redundant-alt */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
// import Header from '../Header';

const DaynamicProducts = () => {
  const [allDataStore, setAllDataStore] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const navigation = useNavigate();

  const GetAllData = async () => {
    try {
      const res = await axios.get('https://martha-move-up.developer-iu.xyz/api/client/products');
      setAllDataStore(res?.data?.products?.data || []); // Ensure it's an array
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  useEffect(() => {
    GetAllData();
  }, []);

  // Return loading state or content
  if (loading) {
    return (
      <>
      <div className='w-full flex justify-center items-center h-[50vh]'>
        <span className="loaderCSSSS"></span>
      </div>
      </>
    );
  }

  // Return content based on data availability
  return (
    <div className="">
      {allDataStore.length > 0 ? (
        allDataStore.map((product, key) => (
          <div key={key} className="bg-white rounded-2xl w-[50vw] shadow-2xl m-2 overflow-hidden hover:shadow-3xl">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-br from-purple-400 to-indigo-600 opacity-75"></div>
              <img src={product?.picture} alt="Product Image" className="w-full h-64 object-cover object-center relative z-10" />
              <div className="absolute font-body top-4 right-4 bg-color1 text-[16px] md:text-[20px] font-bold px-3 py-2 rounded-full z-20 transform rotate-12">
                NEW
              </div>
            </div>
            <div className="p-6">
              <h2 className="text-[18px] md:text-[35px]  text-color2 mb-2 font-header">{product?.name}</h2>
              <p className="text-color4 mb-4 font-body text-[18px] md:text-[23px]">{product?.description}</p>
              <div className="flex items-center justify-between mb-4">
                <span className=" text-color5 font-body text-[18px] md:text-[23px]">${product?.price}</span>
                <div className="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <span className="ml-1 text-gray-600 text-[18px] md:text-[23px]">5 (4 reviews)</span>
                </div>
              </div>
              <button onClick={() => navigation(`/detail/${product?.id}`)} className="w-full font-body bg-color2 text-white py-3 px-4 rounded-lg hover:bg-color4 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg text-[18px] md:text-[22px]">
                Add to Cart
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className='w-full flex justify-center items-center h-[50vh]'>
          <p className="text-color4">No products available</p> {/* Message for no products */}
        </div>
      )}
    </div>
  );
};

export default DaynamicProducts;