// import { List } from 'flowbite-react'
import React from 'react'
// import { HiCheckCircle } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';

const Hero = () => {
    const Navigation = useNavigate()
    return (
        <>
            <section class="bg-color5 dark:bg-[#959d89]">
                <div class="grid max-w-screen-xl items-center justify-center px-4 py-8 mx-auto xl:gap-0 lg:py-16 lg:grid-cols-12">
                    <div class="mr-auto pl-10 place-self-center lg:col-span-7">
                        {/* <h1 class="max-w-2xl mb-4 md:text-4xl font-medium tracking-tight leading-none xl:text-6xl text-white uppercase dark:text-white font-header">Elevate Your Life With Words</h1> */}
                        <p class="font-light text-white  text-[20px] md:text-[30px] dark:text-white font-header">Hi,
                        </p>
                        <p class=" font-light text-white  text-[20px] md:text-[30px] dark:text-white font-header">My name is Martha Sims. I am the founder and creator of Martha Moves Up.  I’m so
                        glad you are here.
                        </p>
                        <div className=' font-light text-white  text-[20px] md:text-[28px] dark:text-white font-header'>
                            <p>  I know what it is like to struggle with depression, anxiety, and to fight
the daily battle of “I am good enough?” You are not alone!</p>

                            <p>I create products that help you fight back against negative thoughts and give your
brain positive things to think about. Using focus words with positive quotes and
powerful affirmations has changed my life and improved my mental health.
</p>

                            <p>I know using focus words can help you too! Elevate your life with words!</p>
                          
                           

                        </div>
                        <div className='w-[100px] m-auto mt-5 p-4 text-[20px] md:text-[25px] bg-color2 text-white rounded-2xl flex justify-center'>
                                <button className='text-center' onClick={() => Navigation('/about-us')}>
                                    About Us
                                </button>
                            </div>
                    </div>
                    <div class="hidden m-auto lg:mt-0 relative  lg:col-span-5 lg:flex h-[80vh]">
                        {/* <video className='h-[70vh] w-[100%] absolute left-16' autoPlay muted>
                            <source src="/Images/Vdo.mp4" type="video/mp4"/>
                        </video> */}
                        <img src='/Images/real.png' alt='' className='' />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero