/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Dropdown, Navbar } from "flowbite-react";
import { IoIosSearch } from "react-icons/io";
import { CiUser } from "react-icons/ci";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
const Header = () => {
    const naviagte = useNavigate()
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [allDataStore, setAllDataStore] = useState([])
    const token = localStorage.getItem("token");

    // Toggle search bar visibility
    const toggleSearchBar = () => {
        setIsSearchVisible((prev) => !prev);
    };

    const handleSignOut = () => {
        localStorage.clear()
        naviagte('/login')
    }


    const HandleLogin = () => {
        naviagte('/login')
    }


    const GetAllData = async () => {
        try {
            const res = await axios.get(`https://martha-move-up.developer-iu.xyz/api/user`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            console.log(res?.data?.product, 'res');
            setAllDataStore(res?.data?.user)

        } catch (error) {
            if(error?.response?.data?.status == false){
localStorage.clear()
            }
        }
    }

    useEffect(() => {

        GetAllData()
    }, [token])

    return (

        <Navbar fluid rounded className='md:py-0 md:pr-3 md:pl-0 '>
            <Navbar.Brand href="#" className='w-full md:w-[200px]'>
                <img src="/Images/logo.png" className="mr-0 md:h-[60px] w-full" alt="Flowbite React Logo" />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
            <Link to={'/'}>
                <Navbar.Link className='md:text-[30px] text-[25px]' active>
                    Home
                </Navbar.Link>
                </Link>
                <Link to={'/blog'}>
                <Navbar.Link  className='md:text-[30px] text-[25px]' href="/blog">
                    Blog
                </Navbar.Link>
                </Link>
                <Link to={'/products'}>
                <Navbar.Link  className='md:text-[30px] text-[25px]' href="/products">
                    Product
                </Navbar.Link>
                </Link>
                <Link to={'/cart'}>
                <Navbar.Link  className='md:text-[30px] text-[25px]' href="/cart">Cart</Navbar.Link>
                </Link>
                <Link to={'/about-us'}>
                <Navbar.Link  className='md:text-[30px] text-[25px]' href="/about-us">About Us</Navbar.Link>
                </Link>
            </Navbar.Collapse>
            <div className="flex justify-center items-center md:order-2">
                {isSearchVisible && (
                    <input
                        type="text"
                        className="border border-gray-300 rounded px-3 py-1 me-5"
                        placeholder="Search..."
                    />
                )}
                <IoIosSearch className="text-xl cursor-pointer me-5" onClick={toggleSearchBar} />

                {/* Conditionally Render Search Input */}


                {
                    (localStorage.getItem("token") == undefined || !localStorage.getItem("token")) ?
                      <CiUser className='text-xl cursor-pointer' onClick={HandleLogin} /> :  
                      <Dropdown
                      arrowIcon={false}
                      inline
                      label={
                          <CiUser className='text-xl ' />
                      }
                  >
                      <Dropdown.Header>
                          <span className="block text-[20px]">{allDataStore?.first_name}{" "}{allDataStore?.last_name}</span>
                          <span className="block text-[20px]  truncate font-medium">{allDataStore?.email}</span>
                      </Dropdown.Header>
                      <Link to="/orders"  ><Dropdown.Item className='text-[20px]'>Orders</Dropdown.Item></Link>
                      {/* <Dropdown.Item>Settings</Dropdown.Item>
                  <Dropdown.Item>Earnings</Dropdown.Item> */}
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={handleSignOut} className='text-[20px]'>Sign out</Dropdown.Item>
                  </Dropdown>
                }

            </div>
        </Navbar>
    )
}

export default Header
